<template>
  <v-layout
    class="fill-height fill-width"
    v-bind:style="{ 'background-image': dataCRM.bgColor }"
    align-center
    justify-center
  >
    <v-card flat class="radius elevation-24 rounded-xl" width="320">
      <template v-if="!loading">
        <v-card-title
          class="headline primary white--text align-center justify-space-between"
        >
          {{ fatwo ? "Confirm 2FA" : "Enable 2FA" }}
        </v-card-title>
        <v-card-text
          :class="!fatwo ? '' : 'mt-4'"
          class="d-flex flex-column align-center mt-2"
        >
          <template v-if="!fatwo">
            <v-img alt="qr" height="250" width="250" :src="imgQr"> </v-img>
          </template>

          <div>
            <v-text-field label="Code" v-model="code" outlined dense></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" text @click="cancelEnable">cancel</v-btn>
          <template v-if="fatwo">
            <v-btn
              color="success"
              :loading="loadingQr"
              :disabled="loadingQr || code == null || code == ''"
              text
              @click="confirmTwoFa"
              >Confirm</v-btn
            >
          </template>
          <template v-else>
            <v-btn
              color="success"
              :loading="loadingQr"
              :disabled="loadingQr || code == null || code == ''"
              text
              @click="enableTwoFa"
              >Enable</v-btn
            >
          </template>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="d-flex flex-column align-center mt-2">
          <v-img
            alt="loading"
            height="250"
            width="250"
            src="../../assets/img/loading.gif"
          >
          </v-img>
        </v-card-text>
      </template>
    </v-card>
  </v-layout>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import { apiBaseUrl } from "@/enviorment";
import { notifyError, notifyInfo } from "../Notification";
import store from "@/store";
export default {
  data() {
    return {
      fatwo: false,
      loading: false,
      imgQr: null,
      loadingQr: false,

      code: "",
      codedisable: "",
      dialogDisable: false,
      loadingDisable: false,
    };
  },

  computed: {
    ...mapState(["profile", "dataCRM", "tokenTwoFa", "needTwoFAuth"]),
  },
  watch: {
    needTwoFAuth(val) {
      this.fatwo = val;
    },
  },
  mounted() {
    this.loading = false;
    this.fatwo = this.needTwoFAuth;
    if (this.fatwo == false) {
      this.getTwoFa();
    }
  },

  methods: {
    ...mapActions(["actGeneralConfig", "actGetContacts", "continueLogin"]),
    ...mapMutations(["mutNeedTwoFA", "updateStatus"]),
    changeFA() {
      if (this.fatwo == true) {
        this.getTwoFa();
      } else {
        this.gotoDisableTwoFA();
      }
    },

    getTwoFa() {
      const token = store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };
      this.loading = true;
      const url = `${apiBaseUrl}/users/getTwoFaQr`;
      axios({
        method: "get",
        url: url,
        responseType: "blob",
        headers: auth,
      })
        .then((response) => {
          const buffer = new Blob([response.data]);
          const file_path = URL.createObjectURL(buffer);
          this.imgQr = file_path;
          this.loading = false;
        })
        .catch((error) => {
          if (error.toString().includes("401")) {
            this.$router.push("/auth");
          } else {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          }
        });
    },
    cancelEnable() {
      this.loadingQr = false;
      this.fatwo = false;
      this.code = "";
      this.imgQr = null;
      this.$store.dispatch("forceLogout");
    },
    enableTwoFa() {
      this.loadingQr = true;
      getAPI
        .put("/users/enableTwoFa", { code: this.code })
        .then((res) => {
          this.mutNeedTwoFA(true);
          this.loadingQr = false;
          notifyInfo("2FA has been enabled successfully");
          this.cancelEnable();
        })
        .catch((error) => {
          this.loadingQr = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    confirmTwoFa() {
      this.loading = true;

      const auth =
        this.tokenTwoFa != undefined && this.tokenTwoFa != null && this.tokenTwoFa != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${this.tokenTwoFa}`,
            }
          : {
              Accept: "*/*",
            };

      const url = `${apiBaseUrl}/users/employee/twFalogin`;
      axios({
        method: "post",
        url: url,
        data: { code: this.code },
        headers: auth,
      })
        .then((response) => {
          this.continueLogin(response.data);
          this.nextStepLogin();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loading = false;
        });
    },

    async nextStepLogin() {
      try {
        this.loading = true;
        await this.actGeneralConfig();
        this.$vuetify.theme.themes.light = this.dataCRM.themes.light;

        this.updateStatus("Idle");
        this.loading = false;
        this.$router.push("/clients");
      } catch (error) {
        this.loading = false;
        let mess = error.response.data.message.toString();
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
  },
};
</script>
<style lang=""></style>
